import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeErase,
    placePlay,
    placePause,
    placeTest,
    cycle,
    writeMiddleText,
    writeLeftText,
    writeRightText,
    writeMiddleHTML,
    writeLeftHTML,
    writeRightHTML,
    placeGo,
    placeStartOver
} from '../../Utils.js';
const Boxes = {
  box1: function(){
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [0, 6, 12, -6],
      keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
    brd1.options.image.highlight=false;
    placeLogo(brd1);
    makeResponsive(brd1);
    var shuffle =placeShuffle(brd1,'left');
    var erase = placeErase(brd1);
    var list1=['Na', 'Ca', 'Al','Ti'];
    var list2=['NaCl', 'CaCl_2', 'AlCl_3','TiCl_4'];
    let  Cl =[1, 2, 3, 4];
    var list=['Sodium', 'Calcium', 'Aluminum','Titanium'];
    var i=0;
//    shuffle.setLabel('&nbsp; &nbsp; Shuffle')
//    shuffle.label.setAttribute({anchorX:'left', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
  //  shuffle.on('over', function () {this.label.setAttribute({visible:true});});
  //  shuffle.on('out', function () {this.label.setAttribute({visible:false});});
    //erase.on('down', function(){clearInputFields(brd1)});
//    erase.setLabel('&nbsp; &nbsp; Start Over')
//    erase.label.setAttribute({anchorX:'left', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
  //
    let title =placeTitle(brd1, function(){return 'Chlorination of '+ list[i.valueOf()]}, "(Enter the missing coefficients to balance the equation)");
//
    var Cmethane = brd1.create('input', [1.00, 3.5, '?', ' '], {cssStyle:'width:8.3333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
//
    brd1.create('text', [2.0, 3.5, function(){return '&nbsp;' + list1[i.valueOf()]+' +'}], {highlight:false, anchorX:'left', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //////////////////////////////////////////
    var Coxy = brd1.create('input', [3, 3.5, '?', ' '], {cssStyle: 'width:8.33333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
//
    brd1.create('text', [4, 3.5, '&nbsp; HCl'], {highlight:false, fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('image',['/assets/random.svg', [5.75, 2.7], [1., 1]],{rotate:45, highlight:false, fixed:true});
//
    var Ccotwo = brd1.create('input', [7, 3.5, '?', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
//
    brd1.create('text', [8, 3.5, ()=>'&nbsp;'+list2[i.valueOf()]+'+'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    var Cwater = brd1.create('input', [9, 3.5, '?', ' '], {cssStyle: 'width:8.3333333333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
//
    brd1.create('text', [10, 3.5, '&nbsp; H_2'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('line', [[1, 2.],[5, 2.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
//
    brd1.create('line', [[1, 2.05],[5, 2.05]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
//
    brd1.create('text', [2.5, 1.5, '<b> REACTANT SIDE</b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('text', [8.5, 1.5, '<b> PRODUCT SIDE </b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('line', [[1, 1.],[5, 1.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
//
    brd1.create('line', [[1, 1.05],[5, 1.05]], {highlight:false,strokeColor:'grey', strokeWidth:1, fixed:true});
// REACTION SIDE
    brd1.create('text', [2.5, 0.5, function(){if(Cmethane.Value()=='?'){return '? Moles of '+list1[i.valueOf()]} else {return 1*Cmethane.Value() + ' Moles of '+list1[i.valueOf()]}}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:	'fontFamily:Oswald;'});
//
    brd1.create('image', ['/assets/check.svg', [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Ccotwo.Value()==(i.valueOf()+1)*Cmethane.Value() && 1*Cmethane.Value()>0} });
//
    brd1.create('text', [2.5, -0.5, function(){if(Coxy.Value()=='?'){return '? Moles of Cl'} else {return 1*Coxy.Value() + ' Moles of Cl'}}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('image', ['/assets/check.svg', [9.6, -0.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Coxy.Value() == 1*Ccotwo.Value()&& 1*Coxy.Value()>0} });
//
    brd1.create('text', [2.5, -1.5, function(){if(Coxy.Value()=='?'){return '? Moles of '+list1[i.valueOf()]} else {return (1)*Coxy.Value() + ' Moles of H'}}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('image', ['/assets/check.svg', [9.6, -1.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 2*Cwater.Value() == 1*Coxy.Value()} });
// PRODUCT SIDE
    brd1.create('text', [8.5, 0.5, function(){if(Ccotwo.Value()=='?'){return '? Moles of '+list1[i.valueOf()]} else{return 1*Ccotwo.Value() + ' Moles of '+list1[i.valueOf()]}}], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('text', [8.5, -0.5, function(){if(Ccotwo.Value()=='?'){return '? Moles of Cl'} else{return Cl[i.valueOf()]*Ccotwo.Value()+ ' Moles of Cl'}}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('text', [8.5, -1.5, function(){if(Cwater.Value()=='?'){return '? Moles of H'} else{ return 2*Cwater.Value() + ' Moles of H'}}], {anchorX:'middle',highlight:false,anchorY:'middle', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd1.create('line', [[1, -2],[5, -2]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
//
    brd1.create('line', [[1, -1.95],[5, -1.95]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
    //
    brd1.create('text', [6, -2.5,'Balanced or Not Balanced?'],{anchorX:'middle', anchorY:'middle', highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //
    var res = brd1.create('text', [6, -3.5, function(){if(1*Ccotwo.Value()== 1*Cmethane.Value() && 1*Coxy.Value() == Cl[i.valueOf()]*Ccotwo.Value() && 2*Cwater.Value() == 1*Coxy.Value()) {return 'The Equation is Now Balanced'} else {return 'The Equation is Not Yet Balanced'}}], {anchorX:'middle', anchorY:'middle', highlight:false,fontSize:function(){if(Cmethane.Value()==0){return 0} else{return 24*brd1.canvasHeight/800}}, cssStyle:'fontFamily:Oswald;', fixed:true});
    //
    shuffle.on('down', function(){return i=Math.floor(Math.random()*4)});
    shuffle.on('down', function(){
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "?";}
    });
    erase.on('down', function(){
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "?";}
    });
  //  brd1.unsuspendUpdate();
  },
}
export default Boxes;
