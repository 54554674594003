<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Chlorination Reactions </span>
      </div>
      <div ref="ia" style="margin-top: 10px" />
      Chlorination reactions are chemical reactions in which a metal reacts with hydrochloric acid (\(HCl\)) to produce a metal chloride. The reaction also produces hydrogen gas (\(H_2\)) as a byproduct.
      <br><br>
      Let's consider the chlorination of a metal called Megnesium &mdash; the chemical symbol for which is \(Mg\).
      During chlorination of \(Mg\) (Megnesium), it reacts with \(HCl\) (Hydrochloric acid) and produces \(MgCl_2\) (Magnesium Chloride) and \(H_2\) (Hydrogen gas). <br><br> So, the chemical equation describing the chlorination of Magnesium looks something like this &mdash;
      $$ Mg + 2\ HCl \rightarrow   MgCl_2 +  H_2$$
      Is this chemical equation balanced or unbalanced? <br><br>
      This answer is balanced because masses of all the chemical elements involved in the reaction (i.e. \(Mg\), \(Cl\) and \(H\)) remain constant before and after the reaction.
      <div style="margin-top: 10px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span id="mgk" ref="pg" style="font-family:Oswald"> Learn interactively : Balancing a Chlorination Reaction </span>
      </div>

      <div ref="ib" style="margin-top: 10px" />
      <h5>Session Objectives</h5>
      <div style="margin-top: 5px" />
      This visually interactive illustration is designed to help you learn how to balance a chemical equation describing chlorination of metals in a step-by-step fashion. <br><br>
      Shown below is a chemical equation that describes the chlorination of a metal.
      You will notice that the coefficients of the reactants and products in the equation are missing. The goal is determine the correct set of coefficients that will balance the chemical equation.
      <div style="margin-top: 10px" />
      <h5>Getting Started</h5>
      <div style="margin-top: 5px" />
      To get started &mdash; <br><br>
      1. Start with entering the coefficients for the reactants and the products in the chemical equation.</br><br>
      2. Once you have provided the coefficients, the MagicGraph automatically calculates the number of moles of each chemical element on the reactant side as well as on the product side. <br><br>
      3. The chemical equation is balanced if the mass of each chemical element before the reaction is same as the mass after the reaction.<br><br>
      4. You can adjust the coefficients to balance the mass of each chemical element before and after the reaction.
      <div ref="ic" style="margin-top: 10px" />
    </div>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <v-layout justify-center>
      <h5 class="edliy-text-about">
        Navigation Tip: Tap on <i class="shuffler ma-1" /> icon to shuffle. Tap on <i class="eraser ma-1" /> icon to clear your response and start over.
      </h5>
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'BalancedEquation',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Chlorination of Metals';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Chemical Equation?',img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Balanced versus unbalanced Equation',img:'/assets/number-3.svg', action: () => this.goto('ib')},
      {title: 'Balancing a Chemical Equation', img:'/assets/touch.svg', action: () => this.goto('ic')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceChem', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Chlorination of Metals',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
